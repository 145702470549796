
import pic1a from "./Group 11.png";
import pic2a from "./Desktop - 4.png";
import pic3a from "./Desktop - 10.png";
import pic8a from './Group 26.png';

export const media = [
    pic1a, 
    pic2a, 
    pic3a, 
    pic8a 
];

export const mediaByIndex = index => media[index % media.length];
