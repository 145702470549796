import React, { useState, useRef, useCallback } from "react";
import { SideButtonLeft, SideButtonRight } from "./EmblaCarouselButtons";
import useInterval from "./useInterval";
import useEmblaCarousel from "embla-carousel-react";
import { mediaByIndex } from "./media/media";
import "./embla.css";

const EmblaCarousel = ({ slides, autoplay, delayLength }) => {
  var pause = false;
  window.addEventListener(
    "focus",
    function () {
      pause = false;
      setDelay(7000);
    },
    false
  );

  window.addEventListener(
    "blur",
    function () {
      pause = true;
    },
    false
  );

  var slide = useRef(0);

  function fadeSlide(index) {
    index = index += 1;
    var previous;
    var next;
    if (index === 1) {
      previous = 4;
    } else {
      previous = index - 1;
    }
    if (index === 4) {
      next = 1;
    } else {
      next = index + 1;
    }

    document.querySelector(
      `.embla__slide:nth-child(${previous.toString()}) .embla__slide__inner .embla__slide__img`
    ).style.opacity = "0.4";
    document.querySelector(
      `.embla__slide:nth-child(${index}) .embla__slide__inner .embla__slide__img`
    ).style.opacity = "1";
    document.querySelector(
      `.embla__slide:nth-child(${next.toString()}) .embla__slide__inner .embla__slide__img`
    ).style.opacity = "0.4";
  }

  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    draggable: false,
    containScroll: "trimSnaps",
    speed: 8,
  });

  const [prevBtnEnabled] = useState(false);
  const [nextBtnEnabled] = useState(false);

  const scrollNext = useCallback(() => {
    if (pause) return;
    var previous;
    if (!embla) return;
    embla.scrollNext();
    slide.current += 1;
    previous = slide.current - 1;
    if (slide.current === 4) {
      slide.current = 0;
    } else if (previous === -1) {
      previous = 3;
    }

    fadeSlide(slide.current);
  }, [embla]);

  const scrollPrev = useCallback(() => {
    var next;
    if (!embla) return;

    slide.current -= 1;
    next = slide.current + 1;
    embla.scrollPrev();
    if (slide.current === -1) {
      slide.current = 3;
    } else if (next === 4) {
      next = 0;
    }

    fadeSlide(slide.current);
  }, [embla]);

  const [delay, setDelay] = useState(delayLength);
  const isRunning = useState(autoplay);

  useInterval(
    () => {
      scrollNext();
    },
    isRunning ? delay : null
  );

  function setAutoPlay() {
    setDelay(7000);
  }
  function handleDelayChange(e) {
    setDelay(e);
    setTimeout(function () {
      setAutoPlay();
    }, e);
  }

  return (
      <div className="embla">
        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {slides.map((index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__inner">
                  <img
                    className="embla__slide__img"
                    src={mediaByIndex(index)}
                    alt="Jacqueline's Photography"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="shadow1"></div>
          <div className="shadow2"></div>
        </div>
        <SideButtonLeft
          onClick={() => {
            scrollPrev();
            handleDelayChange(7000);
          }}
          enabled={prevBtnEnabled}
        />
        <SideButtonRight
          onClick={() => {
            scrollNext();
            handleDelayChange(7000);
          }}
          enabled={nextBtnEnabled}
        />
      </div>
  );
};

export default EmblaCarousel;
