import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Page1 />} />
      <Route path="/viewmore" element={<Page2 />} />
      <Route path="/breakdown" element={<Page3 />} />
    </Routes>
    </Router>
      
  );
}

export default App;
