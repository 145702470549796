import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmblaCarousel from "./EmblaCarousel.js";
import backButton from "./Group 24.png";
import downArrow from "./Vector copy.png";
import pic4a from "./media/Group 11.png";
import pic5a from "./media/Desktop - 4.png";
import pic6a from "./media/Desktop - 10.png";
import pic7a from "./Desktop - 28 1.png";
import pic8a from "./media/Group 26.png";

const SLIDE_COUNT = 4;
const slides = Array.from(Array(SLIDE_COUNT).keys());

const Page2 = () => {
  window.scrollTo(0, 0);

  function scrollDown() {
    window.scrollTo({
      top: window.pageYOffset + 500,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div id="v2" style={{ flexDirection: "column" }}>
        <Link to="/">
          <img
            className="home-button"
            src={backButton}
            alt="button to redirect to home page. displays arrow and jacqueline rhoda"
          ></img>
        </Link>

        <div className="text-group">
          <div className="headline">Personal Projects</div>
          <div className="subheadline">
            Due to the nature of NDA laws, some projects are hidden. The
            projects below are here to showcase my personal style.
          </div>
          <img
            onClick={scrollDown}
            className="down-arrow"
            src={downArrow}
            alt="down arrow"
          ></img>
        </div>
      </div>
      <div className="slides">
      <EmblaCarousel slides={slides} autoplay delayLength={6000} />
      </div>

      <div id="v2a" style={{ flexDirection: "column" }}>
        <div className="design-col">
          <img
            id="img-1a"
            className="img-col"
            src={pic4a}
            alt="Personal Account login Design"
          ></img>
          <img
            id="img-1b"
            className="img-col"
            src={pic5a}
            alt="Sign In Page UI Design"
          ></img>
          <img
            id="img-1c"
            className="img-col"
            src={pic6a}
            alt="Blog Post Page UI Design"
          ></img>
          <img
            id="img-1d"
            className="img-col"
            src={pic8a}
            alt="Apartment Template UI Design"
          ></img>
        </div>
        <div className="text-group">
          <div className="headline">Nespresso Redesign</div>
          <div className="subheadline">
            Not feeling that the Nespresso website correctly reflected the
            brand's identity, I took it upon myself to redesign sections of the
            site in the project below.
          </div>
        </div>
        <div className="img-btn-box">
          <img
            className="img2a"
            src={pic7a}
            alt="Nespresso website redesign"
          ></img>
          <Link to="/breakdown">
            <button className="view-button">View The Full Case Study</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Page2;
