import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import pic1 from "./Group 27.png";
import pic2 from "./Screen Shot 2023-01-18 at 12.25.png";
import pic3 from "./Desktop - 28 1.png";
import pic4 from "./Group 21.png";
import pic5 from "./Desktop - 25 (1) 1.png";
import pic6 from "./Group 22 (1) 1.png";
import pic7 from "./Desktop - 26 (2) 1.png";
const Page3 = () => {
    window.scrollTo(0, 0);
  return (
    <>
      <div
        id="v3"
        style={{ justifyContent: "center", flexDirection: "column", backgroundColor: "#F5F5F5" }}
      >
        <Link to="/">
          <img className="home-button1" src={pic1} alt="Jacqueline Rhoda" />
        </Link>
        <div className="page3">
        <div className="box1">
          <div id="text1-box" className="box1a">
            <div className="vertical-line"></div>
            <div className="breakdown-box">
              <div className="pin-point">What Works</div>
              <div className="pin-point-sub">Fantastic custom font</div>
              <div className="pin-point-sub">Great photography</div>
              <div className="pin-point-sub">Recognizable & scalable logo</div>
              <div className="line"></div>
              <div id="spacing" className="pin-point">
                What Doesn't Work
              </div>
              <div className="pin-point-sub">
                Over-cluttered / needs to be simplified
              </div>
              <div className="pin-point-sub">
                Website does not feel as premium as their packaging & brand
                identity
              </div>
              <div className="pin-point-sub">
                Too much happening in navigation bar(s)
              </div>
              <div className="pin-point-sub">Poor use of white space</div>
              <div className="pin-point-sub">
                Page icons are too detailed to be that small
              </div>
              <div className="pin-point-sub">
                Too many promotions being displayed
              </div>
            </div>
          </div>
          <div className="box1b">
            <div className="before-text">Before Redesign</div>
            <img
              className="img1"
              src={pic2}
              alt="Before nespresso redesign home page"
            />
          </div>
        </div>
        <div className="box2">
          <div className="after-text">After Redesign</div>
          <img
            className="img2"
            src={pic3}
            alt="After redesign of nespresso home page"
          />
        </div>

        <div className="box1">
          <div id="text2-box" className="box1a">
            <div className="vertical-line"></div>
            <div className="breakdown-box">
              <div className="pin-point">
                <a>What Works</a>
              </div>
              <div className="pin-point-sub">
                Product is displayed inside cards
              </div>
              <div className="pin-point-sub">
                Easy access to “Shop All Coffee” page
              </div>
              <div className="pin-point-sub">
                Plus button allows for easy add to cart
              </div>
              <div className="line"></div>
              <div id="spacing" className="pin-point">
                What Doesn't Work
              </div>
              <div className="pin-point-sub">
                Poor use of white space inside cards
              </div>
              <div className="pin-point-sub">
                Missing left arrow button / not a functioning carousel
              </div>
              <div className="pin-point-sub">
                Poor color choices for the cards & green buttons - doesn't feel
                premium
              </div>
              <div className="pin-point-sub">Poor contrast ratio</div>
              <div className="pin-point-sub">
                Overall look feels amateur & does not match brand identity
              </div>
            </div>
          </div>
          <div className="box1b">
            <div className="before-text">Before Redesign</div>
            <img
              className="img1"
              src={pic4}
              alt="Before nespresso redesign trending page"
            />
          </div>
        </div>
        <div className="box2">
          <div className="after-text">After Redesign</div>
          <img
            className="img2"
            src={pic5}
            alt="After redesign of nespresso trending page"
          />
        </div>

        <div className="box1">
          <div id="text3-box" className="box1a">
            <div className="vertical-line"></div>
            <div className="breakdown-box">
              <div className="pin-point">What Works</div>
              <div className="pin-point-sub">Good product photography</div>
              <div className="pin-point-sub">
                Ability to filter product type
              </div>
              <div className="pin-point-sub">
                High importance placed on “+” button
              </div>
              <div className="line"></div>
              <div id="spacing" className="pin-point">
                What Doesn't Work
              </div>
              <div className="pin-point-sub">
                “Woody & Earthy” boxes lack proper white space and alignment
              </div>
              <div className="pin-point-sub">
                Information inside of cards are too crammed / lacks hierarchy /
                lacks proper white space
              </div>
              <div className="pin-point-sub">
                Multiple filter cards at top showing the same thing
              </div>
              <div className="pin-point-sub">
                Intensity scale lacks easy readability / straighforwardness
              </div>
              <div className="pin-point-sub">
                Overall look feels amateur & does not match brand identity
              </div>
            </div>
          </div>
          <div className="box1b">
            <div className="before-text">Before Redesign</div>
            <img
              className="img1"
              src={pic6}
              alt="Before nespresso redesign shop page"
            />
          </div>
        </div>
        <div style={{paddingBottom: "116px", marginBottom: "0px"}} className="box2">
          <div className="after-text">After Redesign</div>
          <img
            className="img2"
            src={pic7}
            alt="After redesign of nespresso shop page"
          />
        </div> 
        </div>
        
      </div>
    </>
  );
};
export default Page3;
