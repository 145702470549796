import ReactPlayer from "react-player";
import arrow from "./Group 1.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Page1 = () => {
  const [widthVideo, setVideoWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setVideoWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div id="v1">
        <div className="layout">
          <div className="intro-text">
            <div className="mainhead">Jacqueline Rhoda</div>
            <div className="subhead">UI & UX Designer</div>
          </div>
          <Link to="/viewmore">
            <div className="video-container1">
              <ReactPlayer
                style={{ minWidth: "290px" }}
                width={widthVideo > 500 ? "490px" : "95%"}
                className="video1"
                controls={false}
                muted={true}
                playing={true}
                loop={true}
                url="https://jaxprojectvideo.s3.amazonaws.com/video.mp4"
              />
            </div>
          </Link>
        </div>
        <Link to="/viewmore">
          {" "}
          <img className="morebutton" src={arrow} alt="arrow" />
        </Link>
      </div>
    </>
  );
};

export default Page1;
